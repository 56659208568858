.mainClock {
  width: 100%;
  text-align: center;
  line-height: 90vh;
}

.mainClock.darkText {
  color: #333;
}

.mainClock.lightText {
  color: #fff;
}

.settingsContainer {
  height: 10rem;
  transition: all 0.3s ease-in-out;
  background: #fff;
  width: 100vw;
}

.settingsContainer.isHidden {
  top: -10rem;
}

.settingsContainer.isVisible {
  top: 0;
}

.sliderDraggable {
  height: 3rem;
}

.ticksContainer {
  margin-top: 48px;
}

.tick {
}

.tick .tickLabel {
  position: absolute;
  width: 80px;
  margin-left: -40px;
  margin-top: 1rem;
  font-size: .75rem;
}
